import { render, staticRenderFns } from "./myPublish.vue?vue&type=template&id=81c36f4c&scoped=true&"
import script from "./myPublish.vue?vue&type=script&lang=js&"
export * from "./myPublish.vue?vue&type=script&lang=js&"
import style0 from "./myPublish.vue?vue&type=style&index=0&id=81c36f4c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81c36f4c",
  null
  
)

export default component.exports